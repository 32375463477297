const MRI_FIELDS = {
  civility: 'Salutation',
  firstname: 'FirstName',
  name: 'LastName',
  nascdate: 'MRI_Birthday__C',
  codepostal: 'PostalCode',
  phone: 'MobilePhone',
  email: 'Email',
  //Collective Only Fields
  society: 'Company',
  numsiret: 'SIRET__c',
  effectif: 'NumberOfEmployees',
  ville: 'City',
  //Static Fields
  country: 'Country',
  CountryCode: 'CountryCode',
  brokerId: 'MRI_BrokerId__c',
  brokerIdSubsidiary: 'MRI_BrokerId_Subsidiary__c',
  mriAction: 'MRI_Action__c',
  ismri: 'IsMRI__c',
  recordType: 'RecordType',
  //BO Fields
  mri_vecteur: 'MRI_Source__c',
  mri_marche: 'MRI_Market__c',
  mri_produit: 'MRI_Product__c',
};

export const TYPES = {
  PRO: 'Prévoyance professionnelle',
  COLLECTIVE: 'Santé et prévoyance collective',
};

export const DEFAULT_TYPE = TYPES.PRO;

export async function mriAdapter(formData) {
  const formEntries = {};
  for (const [key, value] of formData.entries()) {
    formEntries[key] = value;
  }

  const isPro = formEntries.mri_type === TYPES.PRO;
  const dateParsed = formEntries.nascdate
    ? formEntries.nascdate.split('/').reverse().join('-')
    : '';

  const proFields = {
    [MRI_FIELDS.civility]: formEntries.civility || '',
    [MRI_FIELDS.firstname]: formEntries.firstname || '',
    [MRI_FIELDS.name]: formEntries.name || '',
    [MRI_FIELDS.nascdate]: dateParsed,
    [MRI_FIELDS.codepostal]: formEntries.codepostal || '',
    [MRI_FIELDS.phone]: formEntries.phone || '',
    [MRI_FIELDS.email]: formEntries.email || '',
  };
  const collectiveFields = {
    [MRI_FIELDS.society]: formEntries.society || '',
    [MRI_FIELDS.numsiret]: formEntries.numsiret || '',
    [MRI_FIELDS.effectif]: formEntries.effectif || '',
    [MRI_FIELDS.civility]: formEntries.civility || '',
    [MRI_FIELDS.firstname]: formEntries.firstname || '',
    [MRI_FIELDS.name]: formEntries.name || '',
    [MRI_FIELDS.codepostal]: formEntries.codepostal || '',
    [MRI_FIELDS.ville]: formEntries.ville || '',
    [MRI_FIELDS.phone]: formEntries.phone || '',
    [MRI_FIELDS.email]: formEntries.email || '',
  };
  const staticFields = {
    [MRI_FIELDS.country]: 'FR',
    [MRI_FIELDS.CountryCode]: 'FR',
    [MRI_FIELDS.brokerId]: '25437',
    [MRI_FIELDS.brokerIdSubsidiary]: '09999',
    [MRI_FIELDS.mriAction]: 'DemanderMiseEnRelation',
    [MRI_FIELDS.ismri]: true,
    [MRI_FIELDS.recordType]: { Name: 'Lead' },
  };

  const boFields = {
    [MRI_FIELDS.mri_vecteur]: formEntries.mri_vecteur,
    [MRI_FIELDS.mri_marche]: formEntries.mri_marche,
    [MRI_FIELDS.mri_produit]: formEntries.mri_produit,
  };

  const fields = {
    ...(isPro ? proFields : collectiveFields),
    ...staticFields,
    ...boFields,
  };
  return fields;
}
